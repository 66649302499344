<template>
  <div>
    <Landing
      :role="role"
      :tabs="tabs"
      :subTitle="subTitle"
      :mainTabs="mainTabs"
      :stories="stories"
      :storiesTitle="storiesTitle"
    ></Landing>
    <div class="container mb-4">
      <Plan></Plan>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Landing from "@/views/landingPage/components/Index.vue";
import Plan from "@/views/plans/Index.vue";

export default {
  metaInfo() {
    return {
      title: `For ${this.role} - ` + this.CompanyName
    };
  },

  components: { Landing,Plan },

  mixins: [],

  props: [],
  data() {
    return {
      //Role
      role: "Educators",
      //SubTitle
      subTitle: {
        title: "The everything site for test prep instruction",
        smallTitle:
          "From your student's first diagnostic to their final mock test"
      },
      // Tabs
      tabs: [
        {
          icon: "fas fa-user-plus",
          title: "Connect Students",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Connect+Students.png"
        },
        {
          icon: "fas fa-school",
          title: "Set Up Classes",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Set+Up+Classes.png"
        },
        {
          icon: "fas fa-grin-stars",
          title: "Create AI-Powered Study Plans",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Review+Overall+Class+Results.png"
        },
        {
          icon: "fas fa-house-user",
          title: "Assign Homework",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Assign+Homework+.png"
        },
        {
          icon: "fas fa-chart-bar",
          title: "Review Overall Class Results",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Assign+Homework.png"
        },
        {
          icon: "fas fa-mail-bulk",
          title: "Assign Drills",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Assign+Drills.png"
        },
        {
          icon: "fas fa-spell-check",
          title: "Create Custom Questions and Tests",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Create+Custom+Questions+and+Tests.png"
        },
        {
          icon: "fas fa-seedling",
          title: "Oversee",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Ovcersee.png"
        }
      ],
      // mainTabs
      mainTabs: [
        {
          title: "Connect Students",
          text:
            "Get started by creating a game plan for your test prep by using our AI-powered study plan generator",
          img: ""
        },
        {
          title: "Track Progress",
          text:
            "Monitor student progress in real-time, assign drills and practice tests, and analyze results",
          img: ""
        },
        {
          title: "Maximize Results",
          text: "Streamline student management with automated tracking and insights, crushing student goals",
          img: ""
        }
      ],
      // Stories
      stories: [
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "",
          name: ""
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "",
          name: ""
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "",
          name: ""
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "",
          name: ""
        }
      ],
      storiesTitle: {
        title: "Educator Success Stories",
        smallTitle:
          "From high school counselors to non-profit organizers to test prep tutors, <br />these educators used XMocks to help their students crush their goals!",
        count: "3,000"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>
